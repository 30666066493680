import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'

import { useBillingScreenTranslation } from '../hooks/useBillingScreenTranslation'

export const G2AGuideModal = observer(
  ({
    isOpen$,
    onRedeem,
    onPurchase,
  }: {
    isOpen$: Observable<boolean>
    onRedeem: () => void
    onPurchase: () => void
  }) => {
    const { t } = useBillingScreenTranslation()

    return (
      <Modal isOpen={isOpen$.get()} onClose={() => isOpen$.set(false)}>
        <div className="w-[95vw] rounded-lg px-6 py-8 md:w-[500px]">
          <h2 className="mb-6 text-3xl font-bold text-white">{t('g2aGuideModal.title')}</h2>
          <ol
            className="flex list-decimal flex-col space-y-4 pl-8 text-white"
            style={{ listStyleType: 'decimal' }}
          >
            <li className="pl-2 text-lg" style={{ display: 'list-item' }}>
              {t('g2aGuideModal.desc')}
            </li>
            <li className="pl-2 text-lg" style={{ display: 'list-item' }}>
              {t('g2aGuideModal.showKey')}
            </li>
            <li className="pl-2 text-lg" style={{ display: 'list-item' }}>
              {t('g2aGuideModal.goToBilling')}
            </li>
          </ol>
          <div className="mt-12 flex flex-col justify-center gap-4">
            <Button showArrow={false} onClick={onRedeem}>
              {t('g2aGuideModal.redeem')}
            </Button>
            <Button showArrow={false} mode="transparent" onClick={onPurchase}>
              {t('g2aGuideModal.purchase')}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
)
