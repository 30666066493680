import { observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { i18nInstance } from 'app/utils/i18n'
import { openInNewTab } from 'app/utils/redirect'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import enMessages from '../locales/en/redirectModal.json'
import zhMessages from '../locales/zh/redirectModal.json'

i18nInstance.addResourceBundle('en', 'redirectModal', enMessages)
i18nInstance.addResourceBundle('zh', 'redirectModal', zhMessages)

const url$ = observable('')

export const showRedirectModal = (url: string) => {
  url$.set(url)
}

export const closeRedirectModal = () => {
  url$.set('')
}

export const RedirectModal = observer(() => {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation('redirectModal')

  const handleCopy = () => {
    navigator.clipboard.writeText(url$.get())
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <Modal isOpen={!!url$.get()} onClose={closeRedirectModal}>
      <div className="flex items-center justify-center bg-black bg-opacity-50 p-4">
        <div className="w-full max-w-md rounded-lg p-6 shadow-xl">
          <h2 className="mb-4 text-2xl font-bold text-white">{t('title')}</h2>
          <p className="mb-6 text-gray-300">{t('description')}</p>
          <div className="flex flex-col space-y-4">
            <Button onClick={() => openInNewTab(url$.get())} showArrow={false} className="py-3">
              {t('redirectNow')}
            </Button>
            <Button onClick={handleCopy} showArrow={false} mode="transparent" className="py-3">
              {copied ? t('copied') : t('copyLink')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})
