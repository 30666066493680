export const redirect = (url: string) => {
  location.href = url
}

export const openInNewTab = (url: string) => {
  // 创建一个新的<a>元素
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.rel = 'noopener noreferrer'

  // 将链接添加到文档中
  document.body.appendChild(link)

  // 模拟点击链接
  link.click()

  // 从文档中移除链接
  document.body.removeChild(link)
}
