import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import { motion } from 'framer-motion'

import { PERIODS } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PeriodTab = observer(() => {
  const { selectedPeriod$ } = useSubscriptionSection()
  return (
    <div className="mx-auto mt-10 flex w-full items-stretch justify-center gap-4 rounded-lg bg-[#7756E0]/50 p-[10px] md:w-[650px]">
      {PERIODS.map((period) => (
        <button
          key={period}
          onClick={() => selectedPeriod$.set(period)}
          className="relative flex-1 py-2 text-center text-base font-medium text-white"
        >
          {selectedPeriod$.get() === period && (
            <motion.span
              className="absolute inset-0 z-0 block h-full w-full rounded-lg bg-white"
              layoutId="period-tab"
            />
          )}
          <span
            className={cn(
              'relative z-10 flex flex-col items-center justify-center gap-1 font-bold transition-all duration-300 md:flex-row md:gap-2',
              selectedPeriod$.get() === period &&
                'bg-gradient-to-r from-[#7756E0] to-[#0D009B] bg-clip-text text-transparent'
            )}
          >
            <PeriodText period={period} />
            {period > 1 && (
              <span
                className={cn(
                  'w-[80%] rounded bg-white/30 text-xs text-white/60 transition-all duration-300 md:w-auto md:px-2 md:py-1',
                  selectedPeriod$.get() === period && 'text-[#7756E0]'
                )}
              >
                <PeriodDiscount period={period} />
              </span>
            )}
          </span>
        </button>
      ))}
    </div>
  )
})

const PeriodText = ({ period }: { period: number }) => {
  return period < 12 ? `${period} Mo` : `${period / 12} Yr`
}

const PeriodDiscount = ({ period }: { period: number }) => {
  if (period === 3) {
    return '-20%'
  }
  if (period === 6) {
    return '-40%'
  }
  if (period === 12) {
    return '-50%'
  }
  return null
}
