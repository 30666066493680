import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import Modal from 'app/features/ecommerce/components/Modal'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'
import { IconPlus } from 'app/features/ecommerce/icons/IconPlus'
import { capitalize } from 'es-toolkit/string'
import { motion } from 'framer-motion'
import { ShieldQuestionIcon } from 'lucide-react'

import { PaymentTypes } from './PaymentTypes'
import { OnetimeSubscriptionPrices, PaymentType, SubscriptionPrices } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PaymentTypeModal = observer(() => {
  const { t } = useBillingScreenTranslation('subscriptionSection.paymentTypeModal')

  const { selectedTier$, paymentType$, selectedPeriod$, onPayment, paymentMethod$, isPaying$ } =
    useSubscriptionSection()

  const selectedTab = paymentType$.get() === PaymentType.Recurring ? 1 : 0

  const resetPaymentMethod = () => {
    paymentMethod$.set(null)
    onPayment.current = null
  }

  return (
    <Modal
      isOpen={!!selectedTier$.get()}
      onClose={() => {
        selectedTier$.set(null)
        paymentType$.set(PaymentType.Onetime)
        resetPaymentMethod()
        isPaying$.set(false)
      }}
    >
      <div className="box-border flex w-[90vw] flex-col items-center justify-center px-4 py-[36px] md:w-[669px] md:px-[108px]">
        <p className="mb-4 flex items-center justify-center text-2xl font-medium text-white md:mb-6 md:text-4xl">
          {t('title')}
          <a
            href="https://docs.mydreamboy.com/account/subscriptions"
            target="_blank"
            rel="noreferrer"
          >
            <ShieldQuestionIcon className="ml-1 text-white/50 md:ml-2" size={18} />
          </a>
        </p>
        <div className="relative mb-2 flex w-full items-center justify-center gap-4 rounded-[20px] bg-[#14152D80]/50 p-[6px] md:mb-4 md:p-[10px]">
          <TabIndicator selectedTab={selectedTab} />
          <TabButton
            onClick={() => {
              paymentType$.set(PaymentType.Onetime)
              resetPaymentMethod()
            }}
          >
            <p>{t('oneTime')}</p>
          </TabButton>
          <TabButton
            onClick={() => {
              paymentType$.set(PaymentType.Recurring)
              resetPaymentMethod()
            }}
          >
            <div className="flex flex-col items-start">
              <p className="text-xs uppercase md:text-base">{t('recommend')}</p>
              <p className="flex flex-col items-start text-xs md:flex-row md:text-base">
                {t('recurring')}{' '}
                <span className="rounded-full bg-white/10 px-2 py-1 text-xs font-medium text-white">
                  -20%
                </span>
              </p>
            </div>
          </TabButton>
        </div>

        <div className="mt-2 flex w-full items-center justify-between md:mt-4">
          <div>
            <p className="text-sm">{t('seletedTier')}</p>
            <div className="mt-2 flex items-center gap-4">
              <div className="rounded-xl bg-[#0F067D] p-1 md:p-2">
                <IconPlus width={36} height={36} />
              </div>
              <p className="text-3xl font-medium md:text-5xl">
                {capitalize(selectedTier$.get() ?? '')}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-3xl font-medium md:text-4xl">
              $
              {
                (paymentType$.get() === PaymentType.Onetime
                  ? OnetimeSubscriptionPrices
                  : SubscriptionPrices)[selectedPeriod$.get()][selectedTier$.get()]
              }
            </p>
            <p className="text-sm font-medium">Billed Monthly</p>
          </div>
        </div>

        <div className="mt-6 w-full">
          <PaymentTypes />
        </div>

        <motion.button
          className={cn(
            'mt-6 flex w-full items-center justify-center rounded-full bg-gradient-to-r from-[#7756E0] to-[#0D009B] py-[12px] text-lg font-medium text-white md:py-[20px]',
            paymentMethod$.get() ? 'opacity-100' : 'cursor-not-allowed opacity-50'
          )}
          disabled={isPaying$.get() || !paymentMethod$.get()}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          onClick={() => onPayment?.current?.()}
        >
          {isPaying$.get() ? (
            <motion.div
              className="h-6 w-6 rounded-full border-t-2 border-white"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
            />
          ) : (
            t('continue')
          )}
        </motion.button>
      </div>
    </Modal>
  )
})

const TabIndicator = ({ selectedTab }: { selectedTab: number }) => {
  return (
    <motion.div
      className={cn(
        'absolute bottom-[10px] left-[10px] top-[10px] w-[calc(50%-15px)] rounded-[15px]',
        'bg-gradient-to-r from-[#7756E0] to-[#0D009B]'
      )}
      initial={false}
      animate={{
        x: selectedTab === 0 ? '0%' : 'calc(100% + 10px)',
      }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    />
  )
}

const TabButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <button
      className="relative z-10 flex flex-1 flex-col items-center justify-center py-[9.5px] text-xs font-medium text-white transition-colors duration-300 md:text-sm"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
