import { motion, AnimatePresence } from 'framer-motion'
import { X } from 'lucide-react'
import { useEffect } from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'keyframes', damping: 25, stiffness: 300 }}
        >
          <motion.div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
          <motion.div
            className="relative rounded-xl z-10 m-4 bg-[#060606] border border-white/10"
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ type: 'keyframes', damping: 25, stiffness: 300 }}
          >
            <div className="overflow-y-auto max-h-[calc(100vh-2rem)] custom-scrollbar">
              {children}
            </div>
            <div
              className="absolute top-4 right-4 md:top-6 md:right-6 rounded-full p-1 border border-white/60 flex items-center justify-center cursor-pointer"
              onClick={onClose}
            >
              <X className="text-white/60" />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
