import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'

import { Cards } from './components/Cards'
import { PaymentTypeModal } from './components/PaymentTypeModal'
import { PeriodTab } from './components/PeriodTab'
import { SubscriptionSectionProvider } from './providers/SubscriptionSectionProvider'
import { useBillingScreenTranslation } from '../../hooks/useBillingScreenTranslation'

export const SubscriptionSection = observer(
  ({ highLight, onRedeem }: { highLight?: boolean; onRedeem: () => void }) => {
    const { t } = useBillingScreenTranslation()
    return (
      <SubscriptionSectionProvider onRedeem={onRedeem}>
        <div
          className={cn(
            'z-1 relative -mx-4 overflow-hidden px-4 py-[70px] md:-mx-0 md:px-0',
            highLight
              ? 'before-left-0 before:absolute before:inset-0 before:left-1/2 before:w-[1533px] before:-translate-x-1/2 before:bg-gradient-to-r before:from-[#7756E0] before:to-[#0D009B] before:content-[""]'
              : ''
          )}
        >
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/subscription_bg.png"
            className="absolute left-0 top-0 z-0 h-auto max-h-none w-auto max-w-none object-none"
          />
          <div className="z-1 relative">
            <p className="text-center text-3xl font-medium text-white md:text-5xl">
              {t('subscriptionSection.title')}
            </p>
            <p className="mt-4 text-center text-sm text-white md:text-base">
              {t('subscriptionSection.desc1')}
            </p>
            <p className="mt-1 text-center text-sm text-white md:text-base">
              {t('subscriptionSection.desc2')}
            </p>
            <PeriodTab />
            <Cards />
          </div>
        </div>
        <PaymentTypeModal />
      </SubscriptionSectionProvider>
    )
  }
)
