//-----Types.File-----
import type { AxiosError } from 'axios'
export interface GenerateAssistantMessageDto {
  messageId: number
  assistantMessageId: number
  sessionId: string
  topicId: number
  userId: string
  [key: string]: any
}

export function deserializeGenerateAssistantMessageDto(json: string): GenerateAssistantMessageDto {
  const data = JSON.parse(json) as GenerateAssistantMessageDto
  initGenerateAssistantMessageDto(data)
  return data
}

export function initGenerateAssistantMessageDto(_data: GenerateAssistantMessageDto) {
  return _data
}

export function serializeGenerateAssistantMessageDto(
  _data: GenerateAssistantMessageDto | undefined
) {
  if (_data) {
    _data = prepareSerializeGenerateAssistantMessageDto(_data as GenerateAssistantMessageDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeGenerateAssistantMessageDto(
  _data: GenerateAssistantMessageDto
): GenerateAssistantMessageDto {
  const data: Record<string, any> = { ..._data }
  return data as GenerateAssistantMessageDto
}

export interface TaskResultDto {
  tid: string
  userId: string
  assistantMessageId?: number
  [key: string]: any
}

export function deserializeTaskResultDto(json: string): TaskResultDto {
  const data = JSON.parse(json) as TaskResultDto
  initTaskResultDto(data)
  return data
}

export function initTaskResultDto(_data: TaskResultDto) {
  return _data
}

export function serializeTaskResultDto(_data: TaskResultDto | undefined) {
  if (_data) {
    _data = prepareSerializeTaskResultDto(_data as TaskResultDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeTaskResultDto(_data: TaskResultDto): TaskResultDto {
  const data: Record<string, any> = { ..._data }
  return data as TaskResultDto
}

export interface AssistantConfigurationDto {
  /** Model name */
  model?: string
  /** Max content tokens */
  maxContentTokens?: number
  /** Default dialog jailbreak prompt */
  defaultDialogJailbreakPrompt?: string
  /** Default dialog main prompt */
  defaultDialogMainPrompt?: string
  /** Default dialog nsfw prompt */
  defaultDialogNsfwPrompt?: string
  /** Default long story jailbreak prompt */
  defaultLongStoryJailbreakPrompt?: string
  /** Default long story main prompt */
  defaultLongStoryMainPrompt?: string
  /** Default long story nsfw prompt */
  defaultLongStoryNsfwPrompt?: string
  /** Default story jailbreak prompt */
  defaultStoryJailbreakPrompt?: string
  /** Default story main prompt */
  defaultStoryMainPrompt?: string
  /** Default story nsfw prompt */
  defaultStoryNsfwPrompt?: string
  /** Max tokens */
  maxTokens?: number
  quantizations: string[]
  [key: string]: any
}

export function deserializeAssistantConfigurationDto(json: string): AssistantConfigurationDto {
  const data = JSON.parse(json) as AssistantConfigurationDto
  initAssistantConfigurationDto(data)
  return data
}

export function initAssistantConfigurationDto(_data: AssistantConfigurationDto) {
  if (_data) {
    _data.quantizations = _data['quantizations']
  }
  return _data
}

export function serializeAssistantConfigurationDto(_data: AssistantConfigurationDto | undefined) {
  if (_data) {
    _data = prepareSerializeAssistantConfigurationDto(_data as AssistantConfigurationDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeAssistantConfigurationDto(
  _data: AssistantConfigurationDto
): AssistantConfigurationDto {
  const data: Record<string, any> = { ..._data }
  return data as AssistantConfigurationDto
}

export interface SumupCheckoutDetailDto {
  userId: string
  email: string
  type: SumupCheckoutDetailDtoType
  amount: number
  GBP?: number
  credits: number
  subscriptionTier?: SumupCheckoutDetailDtoSubscriptionTier
  period?: number
  cid?: string
  [key: string]: any
}

export function deserializeSumupCheckoutDetailDto(json: string): SumupCheckoutDetailDto {
  const data = JSON.parse(json) as SumupCheckoutDetailDto
  initSumupCheckoutDetailDto(data)
  return data
}

export function initSumupCheckoutDetailDto(_data: SumupCheckoutDetailDto) {
  if (_data) {
    _data.type = _data['type']
    _data.subscriptionTier = _data['subscriptionTier']
  }
  return _data
}

export function serializeSumupCheckoutDetailDto(_data: SumupCheckoutDetailDto | undefined) {
  if (_data) {
    _data = prepareSerializeSumupCheckoutDetailDto(_data as SumupCheckoutDetailDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeSumupCheckoutDetailDto(
  _data: SumupCheckoutDetailDto
): SumupCheckoutDetailDto {
  const data: Record<string, any> = { ..._data }
  return data as SumupCheckoutDetailDto
}

export interface IdatariverOnetimePackagePayDto {
  payMethod: IdatariverOnetimePackagePayDtoPayMethod
  userId: string
  email: string
  credits: number
  [key: string]: any
}

export function deserializeIdatariverOnetimePackagePayDto(
  json: string
): IdatariverOnetimePackagePayDto {
  const data = JSON.parse(json) as IdatariverOnetimePackagePayDto
  initIdatariverOnetimePackagePayDto(data)
  return data
}

export function initIdatariverOnetimePackagePayDto(_data: IdatariverOnetimePackagePayDto) {
  if (_data) {
    _data.payMethod = _data['payMethod']
  }
  return _data
}

export function serializeIdatariverOnetimePackagePayDto(
  _data: IdatariverOnetimePackagePayDto | undefined
) {
  if (_data) {
    _data = prepareSerializeIdatariverOnetimePackagePayDto(_data as IdatariverOnetimePackagePayDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeIdatariverOnetimePackagePayDto(
  _data: IdatariverOnetimePackagePayDto
): IdatariverOnetimePackagePayDto {
  const data: Record<string, any> = { ..._data }
  return data as IdatariverOnetimePackagePayDto
}

export interface IdatariverOnetimeSubscriptionDto {
  payMethod: IdatariverOnetimeSubscriptionDtoPayMethod
  userId: string
  email: string
  period: number
  subscriptionTier: IdatariverOnetimeSubscriptionDtoSubscriptionTier
  credits: number
  [key: string]: any
}

export function deserializeIdatariverOnetimeSubscriptionDto(
  json: string
): IdatariverOnetimeSubscriptionDto {
  const data = JSON.parse(json) as IdatariverOnetimeSubscriptionDto
  initIdatariverOnetimeSubscriptionDto(data)
  return data
}

export function initIdatariverOnetimeSubscriptionDto(_data: IdatariverOnetimeSubscriptionDto) {
  if (_data) {
    _data.payMethod = _data['payMethod']
    _data.subscriptionTier = _data['subscriptionTier']
  }
  return _data
}

export function serializeIdatariverOnetimeSubscriptionDto(
  _data: IdatariverOnetimeSubscriptionDto | undefined
) {
  if (_data) {
    _data = prepareSerializeIdatariverOnetimeSubscriptionDto(
      _data as IdatariverOnetimeSubscriptionDto
    )
  }
  return JSON.stringify(_data)
}

export function prepareSerializeIdatariverOnetimeSubscriptionDto(
  _data: IdatariverOnetimeSubscriptionDto
): IdatariverOnetimeSubscriptionDto {
  const data: Record<string, any> = { ..._data }
  return data as IdatariverOnetimeSubscriptionDto
}

export interface CreateG2aCardDto {
  amount: number
  [key: string]: any
}

export function deserializeCreateG2aCardDto(json: string): CreateG2aCardDto {
  const data = JSON.parse(json) as CreateG2aCardDto
  initCreateG2aCardDto(data)
  return data
}

export function initCreateG2aCardDto(_data: CreateG2aCardDto) {
  return _data
}

export function serializeCreateG2aCardDto(_data: CreateG2aCardDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateG2aCardDto(_data as CreateG2aCardDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeCreateG2aCardDto(_data: CreateG2aCardDto): CreateG2aCardDto {
  const data: Record<string, any> = { ..._data }
  return data as CreateG2aCardDto
}

export interface G2aRedeemDto {
  userId: string
  cardId: string
  cid?: string
  [key: string]: any
}

export function deserializeG2aRedeemDto(json: string): G2aRedeemDto {
  const data = JSON.parse(json) as G2aRedeemDto
  initG2aRedeemDto(data)
  return data
}

export function initG2aRedeemDto(_data: G2aRedeemDto) {
  return _data
}

export function serializeG2aRedeemDto(_data: G2aRedeemDto | undefined) {
  if (_data) {
    _data = prepareSerializeG2aRedeemDto(_data as G2aRedeemDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeG2aRedeemDto(_data: G2aRedeemDto): G2aRedeemDto {
  const data: Record<string, any> = { ..._data }
  return data as G2aRedeemDto
}

export interface MinusCreditsDto {
  userId: string
  credit: number
  source: MinusCreditsDtoSource
  [key: string]: any
}

export function deserializeMinusCreditsDto(json: string): MinusCreditsDto {
  const data = JSON.parse(json) as MinusCreditsDto
  initMinusCreditsDto(data)
  return data
}

export function initMinusCreditsDto(_data: MinusCreditsDto) {
  if (_data) {
    _data.source = _data['source']
  }
  return _data
}

export function serializeMinusCreditsDto(_data: MinusCreditsDto | undefined) {
  if (_data) {
    _data = prepareSerializeMinusCreditsDto(_data as MinusCreditsDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeMinusCreditsDto(_data: MinusCreditsDto): MinusCreditsDto {
  const data: Record<string, any> = { ..._data }
  return data as MinusCreditsDto
}

export interface DeleteUserDto {
  userId: string
  [key: string]: any
}

export function deserializeDeleteUserDto(json: string): DeleteUserDto {
  const data = JSON.parse(json) as DeleteUserDto
  initDeleteUserDto(data)
  return data
}

export function initDeleteUserDto(_data: DeleteUserDto) {
  return _data
}

export function serializeDeleteUserDto(_data: DeleteUserDto | undefined) {
  if (_data) {
    _data = prepareSerializeDeleteUserDto(_data as DeleteUserDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeDeleteUserDto(_data: DeleteUserDto): DeleteUserDto {
  const data: Record<string, any> = { ..._data }
  return data as DeleteUserDto
}

export interface UserFollowDto {
  userId: string
  followerId: string
  [key: string]: any
}

export function deserializeUserFollowDto(json: string): UserFollowDto {
  const data = JSON.parse(json) as UserFollowDto
  initUserFollowDto(data)
  return data
}

export function initUserFollowDto(_data: UserFollowDto) {
  return _data
}

export function serializeUserFollowDto(_data: UserFollowDto | undefined) {
  if (_data) {
    _data = prepareSerializeUserFollowDto(_data as UserFollowDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeUserFollowDto(_data: UserFollowDto): UserFollowDto {
  const data: Record<string, any> = { ..._data }
  return data as UserFollowDto
}

export interface UserInviterDto {
  userId: string
  inviterId: string
  [key: string]: any
}

export function deserializeUserInviterDto(json: string): UserInviterDto {
  const data = JSON.parse(json) as UserInviterDto
  initUserInviterDto(data)
  return data
}

export function initUserInviterDto(_data: UserInviterDto) {
  return _data
}

export function serializeUserInviterDto(_data: UserInviterDto | undefined) {
  if (_data) {
    _data = prepareSerializeUserInviterDto(_data as UserInviterDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeUserInviterDto(_data: UserInviterDto): UserInviterDto {
  const data: Record<string, any> = { ..._data }
  return data as UserInviterDto
}

export interface UpdateFeedPostDto {
  postId: number
  [key: string]: any
}

export function deserializeUpdateFeedPostDto(json: string): UpdateFeedPostDto {
  const data = JSON.parse(json) as UpdateFeedPostDto
  initUpdateFeedPostDto(data)
  return data
}

export function initUpdateFeedPostDto(_data: UpdateFeedPostDto) {
  return _data
}

export function serializeUpdateFeedPostDto(_data: UpdateFeedPostDto | undefined) {
  if (_data) {
    _data = prepareSerializeUpdateFeedPostDto(_data as UpdateFeedPostDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeUpdateFeedPostDto(_data: UpdateFeedPostDto): UpdateFeedPostDto {
  const data: Record<string, any> = { ..._data }
  return data as UpdateFeedPostDto
}

export interface ModelFollowDto {
  modelId: string
  followerId: string
  [key: string]: any
}

export function deserializeModelFollowDto(json: string): ModelFollowDto {
  const data = JSON.parse(json) as ModelFollowDto
  initModelFollowDto(data)
  return data
}

export function initModelFollowDto(_data: ModelFollowDto) {
  return _data
}

export function serializeModelFollowDto(_data: ModelFollowDto | undefined) {
  if (_data) {
    _data = prepareSerializeModelFollowDto(_data as ModelFollowDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeModelFollowDto(_data: ModelFollowDto): ModelFollowDto {
  const data: Record<string, any> = { ..._data }
  return data as ModelFollowDto
}

export interface ModelMakeDto {
  userId: string
  credits: number
  img_paths: string[]
  [key: string]: any
}

export function deserializeModelMakeDto(json: string): ModelMakeDto {
  const data = JSON.parse(json) as ModelMakeDto
  initModelMakeDto(data)
  return data
}

export function initModelMakeDto(_data: ModelMakeDto) {
  if (_data) {
    _data.img_paths = _data['img_paths']
  }
  return _data
}

export function serializeModelMakeDto(_data: ModelMakeDto | undefined) {
  if (_data) {
    _data = prepareSerializeModelMakeDto(_data as ModelMakeDto)
  }
  return JSON.stringify(_data)
}

export function prepareSerializeModelMakeDto(_data: ModelMakeDto): ModelMakeDto {
  const data: Record<string, any> = { ..._data }
  return data as ModelMakeDto
}

export enum SumupCheckoutDetailDtoType {
  Recurring_membership = 'recurring_membership',
  Onetime_membership = 'onetime_membership',
  Onetime_package = 'onetime_package',
}

export enum SumupCheckoutDetailDtoSubscriptionTier {
  Free = 'free',
  Plus = 'plus',
  Premium = 'premium',
  Pro = 'pro',
}

export enum IdatariverOnetimePackagePayDtoPayMethod {
  Alipay = 'alipay',
  Wxpay = 'wxpay',
  Hiicash = 'hiicash',
  OnrampPaypal = 'onramp-paypal',
}

export enum IdatariverOnetimeSubscriptionDtoPayMethod {
  Alipay = 'alipay',
  Wxpay = 'wxpay',
  Hiicash = 'hiicash',
  OnrampPaypal = 'onramp-paypal',
}

export enum IdatariverOnetimeSubscriptionDtoSubscriptionTier {
  Free = 'free',
  Plus = 'plus',
  Premium = 'premium',
  Pro = 'pro',
}

export enum MinusCreditsDtoSource {
  System = 'system',
  Subscription = 'subscription',
  Addon = 'addon',
  Tag2Img = 'tag2Img',
  New_model = 'new_model',
  Tag2Img_failed = 'tag2Img_failed',
  New_model_failed = 'new_model_failed',
  Subscription_period_retract = 'subscription_period_retract',
  Refer = 'refer',
  PostShare = 'post-share',
  Private_model = 'private_model',
  Chat_text = 'chat_text',
  Chat_image = 'chat_image',
  Chat_text_failed = 'chat_text_failed',
  Chat_image_failed = 'chat_image_failed',
  Make_model = 'make_model',
  Make_model_failed = 'make_model_failed',
  Extract_tag = 'extract_tag',
  Extract_tag_failed = 'extract_tag_failed',
}

export class ApiException extends Error {
  message: string
  status: number
  response: string
  headers: { [key: string]: any }
  result: any

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super()

    this.message = message
    this.status = status
    this.response = response
    this.headers = headers
    this.result = result
  }

  protected isApiException = true

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true
  }
}

export function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) throw result
  else throw new ApiException(message, status, response, headers, null)
}

export function isAxiosError(obj: any | undefined): obj is AxiosError {
  return obj && obj.isAxiosError === true
}

//-----/Types.File-----
