import { observer, useComputed, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import { api } from 'app/utils/api'
import {
  IdatariverOnetimeSubscriptionDtoPayMethod,
  IdatariverOnetimeSubscriptionDtoSubscriptionTier,
} from 'app/utils/nest-client.types'
import { openInNewTab } from 'app/utils/redirect'
import { getClientReferenceId } from 'app/utils/trackdesk/client'
import { useUser } from 'app/utils/useUser'
import { inRange } from 'es-toolkit/math'

import {
  AlipayLimit,
  AlipayOption,
  CryptoOption,
  G2AOption,
  GumroadOption,
  HiicashLimit,
  HiicashOption,
  OnetimeSubscriptionG2AShopUrl,
  OnetimeSubscriptionPrices,
  OnrampPaypalLimit,
  OnrampPaypalOption,
  OthersOption,
  PaymentType,
  SubscriptionPrices,
  WechatLimit,
  WechatOption,
} from '../../../constant'
import { G2AGuideModal } from '../../G2AGuideModal'
import { showRedirectModal } from '../../RedirectModal'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PaymentTypes = observer(() => {
  const {
    paymentType$,
    paymentMethod$,
    selectedTier$,
    selectedPeriod$,
    onPayment,
    isPaying$,
    onRedeem,
  } = useSubscriptionSection()
  const { user } = useUser()
  const g2aModalIsOpen$ = useObservable(false)
  const onPayByCrypto = api.ecommerce.payOnetimeMembershipByCrypto.useMutation()
  const onPayByIdatariver = api.ecommerce.payOnetimeMembershipByIdatariver.useMutation()

  const options$ = useComputed<
    { id: string; label: string; available: boolean; onPurchase?: () => void }[]
  >(() => {
    if (paymentType$.get() === PaymentType.Recurring) {
      return [{ ...OthersOption, available: false }]
    } else {
      const Wechat = {
        ...WechatOption,
        available: true,
        onPurchase: async () => {
          isPaying$.set(true)
          const url = await onPayByIdatariver.mutateAsync({
            tier: selectedTier$.peek()! as unknown as IdatariverOnetimeSubscriptionDtoSubscriptionTier,
            period: selectedPeriod$.peek(),
            payMethod: IdatariverOnetimeSubscriptionDtoPayMethod.Wxpay,
          })
          isPaying$.set(false)
          if (url) {
            openInNewTab(url)
            showRedirectModal(url)
          }
        },
      }

      const Alipay = {
        ...AlipayOption,
        available: true,
        onPurchase: async () => {
          isPaying$.set(true)
          const url = await onPayByIdatariver.mutateAsync({
            tier: selectedTier$.peek()! as unknown as IdatariverOnetimeSubscriptionDtoSubscriptionTier,
            period: selectedPeriod$.peek(),
            payMethod: IdatariverOnetimeSubscriptionDtoPayMethod.Alipay,
          })
          isPaying$.set(false)
          if (url) {
            openInNewTab(url)
            showRedirectModal(url)
          }
        },
      }

      const OnrampPaypal = {
        ...OnrampPaypalOption,
        available: true,
        onPurchase: async () => {
          isPaying$.set(true)
          const url = await onPayByIdatariver.mutateAsync({
            tier: selectedTier$.peek()! as unknown as IdatariverOnetimeSubscriptionDtoSubscriptionTier,
            period: selectedPeriod$.peek(),
            payMethod: IdatariverOnetimeSubscriptionDtoPayMethod.OnrampPaypal,
          })
          isPaying$.set(false)
          if (url) {
            openInNewTab(url)
            showRedirectModal(url)
          }
        },
      }

      // const Hiicash = {
      //   ...HiicashOption,
      //   available: true,
      //   onPurchase: async () => {
      //     isPaying$.set(true)
      //     const url = await onPayByIdatariver.mutateAsync({
      //       tier: selectedTier$.peek()! as unknown as IdatariverOnetimeSubscriptionDtoSubscriptionTier,
      //       period: selectedPeriod$.peek(),
      //       payMethod: IdatariverOnetimeSubscriptionDtoPayMethod.Hiicash,
      //     })
      //     isPaying$.set(false)
      //     url && openInNewTab(url)
      //   },
      // }

      const Gumroad = {
        ...GumroadOption,
        available: true,
        onPurchase() {
          let url = `https://moggapp.com/pay?variant=Membership_${`${selectedTier$.get()}_${selectedPeriod$.get()}`}&email=${
            user?.email
          }&user=${user?.id}&gid=lytrm`
          const cid = getClientReferenceId()
          if (cid) {
            url = url + `&cid=${cid}`
          }
          window.open(url, '_blank', 'noreferrer')
        },
      }

      const G2A = {
        ...G2AOption,
        available: true,
        onPurchase: () => {
          g2aModalIsOpen$.set(true)
          setTimeout(() => {
            onG2APurchase()
          }, 2000)
        },
      }

      const Crypto = {
        ...CryptoOption,
        available: true,
        onPurchase: async () => {
          isPaying$.set(true)
          const url = await onPayByCrypto.mutateAsync({
            tier: selectedTier$.peek()!,
            period: selectedPeriod$.peek(),
          })
          isPaying$.set(false)
          if (url) {
            openInNewTab(url)
            showRedirectModal(url)
          }
        },
      }

      const options = [G2A, Gumroad]
      const price = (
        paymentType$.get() === PaymentType.Onetime ? OnetimeSubscriptionPrices : SubscriptionPrices
      )[selectedPeriod$.get() ?? 1][selectedTier$.get() ?? 'plus']

      if (inRange(price, WechatLimit.min, WechatLimit.max)) options.push(Wechat)
      if (inRange(price, AlipayLimit.min, AlipayLimit.max)) options.push(Alipay)
      if (inRange(price, OnrampPaypalLimit.min, OnrampPaypalLimit.max)) options.push(OnrampPaypal)
      // if (inRange(price, HiicashLimit.min, HiicashLimit.max)) options.push(Hiicash)
      options.push(Crypto)
      return options
    }
  })

  const onG2APurchase = () => {
    const url = OnetimeSubscriptionG2AShopUrl[selectedPeriod$.get()][selectedTier$.get()!]
    if (url) {
      openInNewTab(url)
      showRedirectModal(url)
    }
  }

  const onG2ARedeem = () => {
    onRedeem()
    selectedTier$.set(null)
  }

  return (
    <>
      <div className="flex flex-col gap-2 md:gap-4">
        {options$.get().map((option) => (
          <label
            key={option.id}
            className={cn(
              'flex cursor-pointer items-center justify-between rounded-full border border-white/10 px-[12px] py-[10px] md:px-[30px] md:py-[20px]',
              option.available ? 'hover:bg-white/10' : 'cursor-not-allowed opacity-50',
              paymentMethod$.get() === option.id
                ? 'bg-gradient-to-r from-[#7756E0] to-[#0D009B]'
                : ''
            )}
          >
            <input
              type="radio"
              value={option.id}
              checked={paymentMethod$.get() === option.id}
              onChange={() => {
                if (option.available) {
                  paymentMethod$.set(option.id)
                  option.onPurchase && (onPayment.current = option.onPurchase)
                }
              }}
              disabled={!option.available}
              className="sr-only"
            />

            <span className="text-sm font-medium text-white md:text-lg">{option.label}</span>
            {option.available && (
              <div
                className={cn(
                  'flex h-5 w-5 items-center justify-center rounded-full border-2',
                  paymentMethod$.get() === option.id ? 'border-white' : 'border-white/50'
                )}
              >
                {paymentMethod$.get() === option.id && (
                  <div className="h-3 w-3 rounded-full bg-white" />
                )}
              </div>
            )}
            {!option.available && (
              <span className="ml-auto bg-white/10 px-[10px] py-[4px] text-sm text-white">
                Coming Soon
              </span>
            )}
          </label>
        ))}
      </div>
      <G2AGuideModal isOpen$={g2aModalIsOpen$} onRedeem={onG2ARedeem} onPurchase={onG2APurchase} />
    </>
  )
})
