import { YStack } from '@my/ui/src'
import { DiscordLink, DiscordLinkUrl } from 'app/features/socialMedia/DiscordLink/DiscordLink'
import { FacebookLink, FacebookLinkUrl } from 'app/features/socialMedia/FacebookLink/FacebookLink'
import { TwitterLink, TwitterLinkUrl } from 'app/features/socialMedia/TwitterLink/TwitterLink'
import Link from 'next/link'

import { LanguageSwitcher } from './LanguageSwitcher/LanguageSwitcher'

const footerNavs = [
  {
    label: 'Community',
    items: [
      {
        href: DiscordLinkUrl,
        name: 'Discord',
      },
      {
        href: TwitterLinkUrl,
        name: 'Twitter',
      },
      {
        href: FacebookLinkUrl,
        name: 'Facebook',
      },
      {
        href: '/affiliate',
        name: 'Affiliate',
      },
      {
        href: 'mailto:support@mydreamboy.com',
        name: 'Email',
      },
    ],
  },
  {
    label: 'Legal',
    items: [
      { href: '/about', name: 'About' },
      {
        href: '/privacy-policy',
        name: 'Privacy Policy',
      },

      {
        href: '/terms-of-service',
        name: 'Terms of Service',
      },
      {
        href: '/2257',
        name: '2257',
      },
    ],
  },
  {
    label: 'Support and Help',
    items: [
      {
        href: 'https://docs.mydreamboy.com',
        name: 'Help Docs',
      },
      {
        href: 'mailto:support@mydreamboy.com',
        name: 'Contact Support',
      },
    ],
  },
]

export function FooterSection() {
  return (
    <footer>
      <YStack position="relative" id="footer" />
      <div className="mx-auto mb-6 mt-24 w-full max-w-screen-xl xl:pb-2">
        <div className="gap-4 p-4 px-8 py-16 sm:pb-16 md:flex md:justify-between">
          <div className="mb-12 flex flex-col gap-4">
            <Link href="/" className="flex items-center gap-2">
              <img src="/favicon.svg" className="text-primary h-8 w-8" />
              <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
                MyDreamBoy AI
              </span>
            </Link>
            <p className="max-w-xs">AI Porn Generator For Male</p>
            <LanguageSwitcher />
          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-3 sm:gap-10">
            {footerNavs.map((nav) => (
              <div key={nav.label}>
                <h2 className="mb-6 text-sm font-medium uppercase tracking-tighter text-gray-900 dark:text-white">
                  {nav.label}
                </h2>
                <ul className="grid gap-2">
                  {nav.items.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="cursor-pointer text-sm font-[450] text-gray-400 duration-200 hover:text-gray-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 rounded-md border-neutral-700/20 px-8 py-4 sm:flex sm:flex-row sm:items-center sm:justify-between">
          <div className="flex space-x-5 sm:mt-0 sm:justify-center">
            <DiscordLink />
            <TwitterLink />
            <FacebookLink />
          </div>
          <div className='dark:text-gray-400" flex flex-col items-start text-xs text-gray-500 sm:text-center'>
            <span>
              Copyright © {new Date().getFullYear()}{' '}
              <Link href="/" className="cursor-pointer">
                MyDreamBoy AI
              </Link>
              . All Rights Reserved.
            </span>
            <span>MOGGFUTURE INC</span>
            <span>30 N GOULD ST STE R, SHERIDAN, WY 82801</span>
          </div>
        </div>
      </div>
    </footer>
  )
}
